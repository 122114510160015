import React, { useMemo } from 'react'
import { DateField, List, DeleteButton, EditButton, useDataGrid } from '@refinedev/mui'
import { darken, lighten } from '@mui/material/styles'
import { Stack, Typography, Box, Button } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { IDefaultFilters, IDepartment } from 'interfaces'
import { HttpError, useCustomMutation, useInvalidate } from '@refinedev/core'
import { Restore } from '@mui/icons-material'

export function DepartmentList () {
  const invalidate = useInvalidate()
  const { mutate: mutateRestore } = useCustomMutation<IDepartment>()
  const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6)

  const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5)

  const columns = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'id',
        headerName: 'id',
        flex: 1,
        minWidth: 150
      },
      {
        field: 'name',
        headerName: 'Nombre',
        flex: 3,
        minWidth: 300
      },
      {
        field: 'createdAt',
        headerName: 'Criado en',
        flex: 1,
        minWidth: 150,
        type: 'dateTime',
        valueGetter: function params ({ row }) {
          return new Date(row.createdAt)
        },
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        field: 'createdBy.name',
        headerName: 'Criado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.createdBy?.name
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Actualizado en',
        flex: 1,
        minWidth: 150,
        type: 'dateTime',
        valueGetter: function params ({ row }) {
          return new Date(row.updatedAt)
        },
        renderCell: function render ({ row }) {
          return row.updatedAt ? <DateField format='HH:mm D/M/YY' value={row.updatedAt} /> : ''
        }
      },
      {
        field: 'updatedBy.name',
        headerName: 'Actualizado por',
        flex: 1,
        minWidth: 150,
        renderCell: function render ({ row }) {
          return row.updatedBy?.name
        }
      },
      {
        field: 'deletedAt',
        headerName: 'Borrado en',
        flex: 1,
        minWidth: 150,
        type: 'dateTime',
        valueGetter: function params ({ row }) {
          return new Date(row.deletedAt)
        },
        renderCell: function render ({ row }) {
          return row.deletedAt ? <DateField format='HH:mm D/M/YY' value={row.deletedAt} /> : ''
        }
      },
      {
        headerName: 'Acción',
        field: 'actions',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 200,
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              {
                row.deletedAt
                  ? <Button onClick={() => handleRestore(row.id)} color='warning' variant='text' type='button'><Restore/></Button>
                  : <>
                    <EditButton hideText recordItemId={row.id} resource="departments" />
                    <DeleteButton
                      hideText
                      recordItemId={row.id}
                      onSuccess={() => invalidateResource()}
                    />
                  </>
              }
            </Stack>
          )
        }
      }
    ],
    []
  )

  const { dataGridProps } = useDataGrid<IDepartment,
  HttpError,
  IDefaultFilters
  >({
    resource: 'departments',
    meta: {
      operation: 'departments',
      fields: [
        'id',
        'name',
        'createdAt',
        'updatedAt',
        'deletedAt',
        {
          createdBy: ['id', 'name']
        },
        {
          updatedBy: ['id', 'name']
        }
      ]
    },
    pagination: {
      mode: 'off'
    },
    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    }
  })

  const handleRestore = (id: string) => {
    mutateRestore({
      successNotification: {
        type: 'success',
        message: 'Departmento reparado con éxito.',
        undoableTimeout: 3000
      },
      url: 'restoreOneDepartment',
      method: 'patch',
      meta: {
        operation: 'restoreOneDepartment',
        fields: ['id'],
        variables: {
          id
        }
      },
      values: {
        input: id
      }
    }, {
      onSuccess: () => {
        invalidateResource()
      }
    })
  }

  const invalidateResource = () => {
    invalidate({
      resource: 'departmentsWithDeleted',
      invalidates: ['all']
    })
  }

  return (
    <List headerProps={{
      title: <Typography variant="h5">Departamentos</Typography>
    }}>
      <Box
        sx={{
          width: '100%',
          '& .super-app-theme--CANCELADA': {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            '&:hover': {
              bgcolor: (theme) =>
                getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode)
            }
          }
        }}
      >
        <DataGrid
          {...dataGridProps}
          columns={columns}
          getRowClassName={({ row }) => `super-app-theme--${row.deletedAt ? 'CANCELADA' : ''}`}
          autoHeight
        />
      </Box>
    </List>
  )
}
