import React from 'react'
import { useShow } from '@refinedev/core'
import { DateField, RefreshButton, Show } from '@refinedev/mui'
import { Grid, Typography } from '@mui/material'
import { IUser } from 'interfaces'

export function UserShow () {
  const meta = {
    fields: [
      'id',
      'name',
      'email',
      'createdAt'
    ]
  }
  const { queryResult, showId } = useShow<IUser>({
    meta
  })

  const { data, isLoading } = queryResult
  const record = data?.data

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Show isLoading={isLoading} headerProps={{
          title: <Typography variant="h5">Usuario {record?.name}</Typography>,
          action: (
            <RefreshButton resource='users' meta={meta} recordItemId={showId} />
          )
        }}>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight="bold">
                    Nombre
              </Typography>
              <Typography variant="body2">{record?.name}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" fontWeight="bold">
                    E-mail
              </Typography>
              <Typography variant="body2">{record?.email}</Typography>
            </Grid>
            <Grid item xs={record?.updatedAt ? 2 : 3}>
              <Typography variant="body1" fontWeight="bold">
                    Status
              </Typography>
              <Typography variant="body2">{record?.deletedAt ? 'Inativo' : 'Ativo'}</Typography>
            </Grid>
            <Grid item xs={record?.updatedAt ? 2 : 3}>
              <Typography variant="body1" fontWeight="bold">
                    Criado em
              </Typography>
              <Typography variant="body2"><DateField format='HH:mm D/M/YY' value={record?.createdAt} /></Typography>
            </Grid>
          </Grid>
        </Show>
      </Grid>
    </Grid>
  )
}
