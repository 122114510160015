import { AuthBindings } from '@refinedev/core'
import { GraphQLClient } from 'graphql-request'

const TOKEN_KEY: string = process.env.REACT_APP_TOKEN_KEY as string
const client = new GraphQLClient(process.env.REACT_APP_API_URL as string)

export const authProvider: AuthBindings = {
  login: async ({ access, password }) => {
    try {
      const query = `mutation {
      login(
        input: {
          access: "${access}"
          password: "${password}"
        }
      ){
        accessToken
      }
    }`

      const response: any = await client.request(query)
      localStorage.setItem(TOKEN_KEY, response.login.accessToken)

      return {
        success: true,
        redirectTo: '/'
      }
    } catch (err: any) {
      return {
        success: false,
        redirectTo: '/login',
        error: {
          name: 'Login não autorizado!',
          message: ''
        }
      }
    }
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY)
    return {
      success: true,
      redirectTo: '/login'
    }
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY)
    if (token) {
      const query = `query {
        me{
          id
        }
      }`

      client.setHeader('authorization', `Bearer ${token} `)
      const response: any = await client.request(query)
      if (response.me?.id) {
        return {
          authenticated: true
        }
      }
    }
    return {
      authenticated: false,
      error: new Error('Sessão inválida ou expirada!'),
      logout: true,
      redirectTo: '/login'
    }
  },
  getPermissions: async () => {
    const token = localStorage.getItem(TOKEN_KEY)
    if (token) {
      const query = `query {
        me{
          id
          name
        }
      }`

      client.setHeader('authorization', `Bearer ${token} `)
      const response: any = await client.request(query)
      if (response.me?.id) {
        return response.me
      }
    }
    return Promise.reject(new Error('Permission failed'))
  },
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY)
    if (token) {
      const query = `query {
        me{
          id
          name
        }
      }`

      client.setHeader('authorization', `Bearer ${token} `)
      const response: any = await client.request(query)
      if (response.me?.id) {
        return response.me
      }
    }
    return Promise.reject(new Error('User Identity failed'))
  },
  onError: async (error) => {
    if (error?.response?.status === 401) {
      return {
        logout: true,
        redirectTo: '/login',
        error
      }
    }
    return {}
  }
}
