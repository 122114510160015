import React, { useState } from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import { ICustomer, IMapDefault } from 'interfaces'
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polygon,
  useJsApiLoader
} from '@react-google-maps/api'

export function CustomerMap ({
  defaultProps,
  desiredZoomLevel,
  customers,
  departments
}: {
  defaultProps: IMapDefault;
  desiredZoomLevel: number;
  customers: ICustomer[];
  departments: any[];
}) {
  const [mapa, setMapa] = useState<any>(null)
  const [zoomLevel, setZoomLevel] = useState(defaultProps.zoom)
  const [activeMarker, setActiveMarker] = useState(null)
  const google = window.google

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_ID as string,
    libraries: ['places']
  })

  const handleZoomChange = () => {
    if (mapa && mapa.getZoom()) {
      const newZoom = mapa.getZoom()
      setZoomLevel(newZoom)
    }
  }

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }

  const handleOnLoad = (map: any) => {
    setMapa(map)
    const bounds = new google.maps.LatLngBounds()
    customers?.forEach(({ latlog }) => {
      const [lat, lng] = latlog.split(',')
      bounds.extend({ lat: Number(lat), lng: Number(lng) })
    })
    map.fitBounds(bounds)
  }

  if (!isLoaded || loadError) {
    return (
      <Skeleton width="100%">
        <Typography>.</Typography>
      </Skeleton>
    )
  }

  const marker = (customer: ICustomer) => {
    const [lat, lng] = customer.latlog.split(',')
    const shouldShowName = zoomLevel >= desiredZoomLevel

    return (
      <Marker
        key={customer.id}
        position={{ lat: Number(lat), lng: Number(lng) }}
        onClick={() => handleActiveMarker(customer.id)}
        label={shouldShowName
          ? {
            text: customer.name,
            color: 'black',
            fontSize: '14px',
            fontWeight: 'bold'
          }
          : undefined}
      >
        {activeMarker === customer.id
          ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <Box>
                <Typography variant="subtitle1" color="black">
                  {customer.name}
                </Typography>
                <Typography variant="inherit" color="black">
                Vendedor: {customer.seller?.name}
                </Typography>
                <Typography variant="inherit" color="black">
                Departamento: {customer.department?.name}
                </Typography>
                <Typography variant="inherit" color="black">
                Ciudad: {customer.city}
                </Typography>
                <Typography variant="inherit" color="black">
                Ruta: {customer.route}
                </Typography>
              </Box>
            </InfoWindow>
          )
          : null}
      </Marker>
    )
  }

  return (
    <Box
      boxShadow="initial"
      color="HighlightText"
      sx={{ height: '576px', width: '100%', position: 'relative' }}
    >
      <GoogleMap
        onLoad={handleOnLoad}
        onClick={() => setActiveMarker(null)}
        onZoomChanged={handleZoomChange}
        mapContainerStyle={{
          overflow: 'visible',
          width: '100%',
          height: '576px'
        }}
        center={defaultProps.center}
        zoom={defaultProps.zoom}
      >
        {!!customers && customers?.map((customer) => marker(customer))}
        {departments.map((department, index) => (
          <Polygon
            key={index}
            paths={department.coordinates}
            options={{
              strokeColor: department.strokeColor,
              strokeOpacity: 0.9,
              strokeWeight: 2,
              fillColor: department.fillColor,
              fillOpacity: 0.5
            }}
          />
        ))}
      </GoogleMap>
    </Box>
  )
}
