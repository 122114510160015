import React, { useMemo } from 'react'
import { DateField, List, DeleteButton, EditButton, useDataGrid } from '@refinedev/mui'
import { Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { IDefaultFilters, ISeller } from 'interfaces'
import { HttpError } from '@refinedev/core'

export function SellerList () {
  const columns = useMemo<GridColDef<ISeller>[]>(
    () => [
      {
        field: 'name',
        headerName: 'Nombre',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'phone',
        headerName: 'Telefono',
        flex: 1,
        minWidth: 200
      },
      {
        field: 'email',
        headerName: 'E-mail',
        flex: 1,
        minWidth: 250
      },
      {
        field: 'createdAt',
        headerName: 'Criado em',
        flex: 1,
        minWidth: 150,
        type: 'dateTime',
        valueGetter: function params ({ row }) {
          return new Date(row.createdAt)
        },
        renderCell: function render ({ row }) {
          return <DateField format='HH:mm D/M/YY' value={row.createdAt} />
        }
      },
      {
        headerName: 'Ações',
        field: 'actions',
        flex: 1,
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        renderCell: function render ({ row }) {
          return (
            <Stack direction="row" spacing={1}>
              <EditButton hideText recordItemId={row.id} resource="sellers" />
              <DeleteButton
                hideText
                recordItemId={row.id}
              />
            </Stack>
          )
        }
      }
    ],
    []
  )

  const { dataGridProps } = useDataGrid<ISeller,
  HttpError,
  IDefaultFilters
  >({
    resource: 'sellers',

    meta: {
      operation: 'sellers',
      fields: [
        'id',
        'name',
        'phone',
        'email',
        'createdAt',
        'updatedAt',
        'deletedAt',
        {
          createdBy: ['id', 'name']
        },
        {
          updatedBy: ['id', 'name']
        }
      ]
    },

    pagination: {
      mode: 'off'
    },

    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    }
  })

  return (
    <List headerProps={{
      title: <Typography variant="h5">Lista de Vendedores</Typography>
    }}>
      <DataGrid
        {...dataGridProps}
        autoHeight
        columns={columns}
      />
    </List>
  )
}
