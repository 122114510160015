import React, { useContext } from 'react'
import { useGetIdentity, useGetLocale, useSetLocale } from '@refinedev/core'
import {
  AppBar,
  IconButton,
  Avatar,
  Stack,
  FormControl,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from '@mui/material'
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material'

import { ColorModeContext } from 'contexts'
import i18n from 'i18n'
import { HamburgerMenu, RefineThemedLayoutV2HeaderProps } from '@refinedev/mui'

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky = true
}) => {
  const { mode, setMode } = useContext(ColorModeContext)

  const changeLanguage = useSetLocale()
  const locale = useGetLocale()
  const currentLocale = locale()

  const { data: user }: any = useGetIdentity()
  const showUserInfo = user && (user.name || user.avatar)

  return (
    <AppBar position={sticky ? 'sticky' : 'relative'}>
      <Toolbar>
        <Stack
          direction="row"
          width="100%"
          justifyContent={'space-between'}
          alignItems="center"
        >
          <HamburgerMenu />
          <Stack
            direction="row"
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            gap="16px"
          >
            <IconButton
              onClick={() => {
                setMode()
              }}
            >
              {mode === 'dark' ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                disableUnderline
                defaultValue={currentLocale}
                inputProps={{ 'aria-label': 'Without label' }}
                variant="standard"
              >
                {[...(i18n.languages ?? [])].sort().map((lang: string) => (
                  <MenuItem
                    selected={currentLocale === lang}
                    key={lang}
                    defaultValue={lang}
                    onClick={() => {
                      changeLanguage(lang)
                    }}
                    value={lang}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Avatar
                        sx={{
                          width: '16px',
                          height: '16px',
                          marginRight: '5px'
                        }}
                        src={`/images/flags/${lang}.png`}
                      />
                      {lang === 'py' ? 'Español' : lang === 'en' ? 'English' : 'Português'}
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {showUserInfo && (
              <Stack direction="row" gap="16px" alignItems="center">
                {user.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
                {user.name && (
                  <Typography variant="subtitle2">{user?.name}</Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}
