import React, { ReactNode } from 'react'
import { useForm } from '@refinedev/react-hook-form'
import { Controller, FieldValues } from 'react-hook-form'
import { Edit, RefreshButton, useAutocomplete } from '@refinedev/mui'
import { Box, TextField, Grid, Typography, Button, Stack, Autocomplete } from '@mui/material'
import { ICustomer, IDepartment, ISeller } from 'interfaces'
import { HttpError } from '@refinedev/core/dist/interfaces'
import { Save } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

export function CustomerEdit () {
  const params: any = useParams()
  const showId = params.id
  const meta = {
    operation: 'Customers',
    fields: [
      {
        nodes: [
          'id',
          'name',
          'city',
          'route',
          'latlog',
          'createdAt',
          'updatedAt',
          'deletedAt',
          {
            seller: ['id', 'name']
          },
          {
            department: ['id', 'name']
          }
        ]
      }
    ]
  }

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ICustomer, HttpError>({
    refineCoreProps: {
      action: 'edit',
      id: showId,
      resource: 'customers',
      invalidates: ['all'],
      meta
    }
  })

  const { autocompleteProps: departmentsProps } = useAutocomplete<IDepartment>({
    resource: 'departments',

    meta: {
      operation: 'departments',
      fields: [
        'id',
        'name'
      ]
    },

    onSearch: (value) => [
      {
        field: 'name',
        operator: 'contains',
        value: `"%${value}%"`
      }
    ],

    sorters: [
      {
        field: 'name',
        order: 'asc'
      }
    ]
  })

  const { autocompleteProps: sellersProps } = useAutocomplete<ISeller>({
    resource: 'sellers',

    meta: {
      operation: 'sellers',
      fields: [
        'id',
        'name'
      ]
    },

    onSearch: (value) => [
      {
        field: 'name',
        operator: 'contains',
        value: `"%${value}%"`
      }
    ],

    sorters: [
      {
        field: 'name',
        order: 'asc'
      }
    ]
  })

  function validateLatLng (latlog: any) {
    try {
      const [lat, lng] = latlog.split(',')
      return isFinite(lat) && Math.abs(lat) <= 90 && isFinite(lng) && Math.abs(lng) <= 180
    } catch (e) {
      return false
    }
  }

  const handleSubmitEdit = (values: FieldValues) => {
    const { seller, department, latlog, ...rest } = values
    const isValid = validateLatLng(latlog)
    if (!isValid) return alert('Latitud y Longitud invalida')
    onFinish({
      latlog,
      sellerId: seller?.id,
      departmentId: department?.id,
      ...rest
    })
  }

  return (
    <Edit
      headerProps={{
        title: <Typography variant="h5">Editar Cliente</Typography>,
        action: <RefreshButton resource='customers' meta={meta} recordItemId={showId} />
      }}
      footerButtons={<></>}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <form onSubmit={handleSubmit(handleSubmitEdit)}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column' }}
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                {...register('name', { required: 'Nombre es requerido' })}
                defaultValue={' '}
                error={!!errors?.name}
                helperText={errors.name?.message as ReactNode}
                label="Nombre"
                name="name"
                margin="normal"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="department"
                defaultValue={null as any}
                render={({ field }) => (
                  <Autocomplete
                    {...departmentsProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value)
                    }}
                    getOptionLabel={(item) => {
                      return (
                        departmentsProps?.options?.find(
                          (p) =>
                            p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ''
                      )
                    }}
                    isOptionEqualToValue={(
                      option,
                      value
                    ) =>
                      value === undefined || option.id.toString() === value.toString()
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Departamento"
                        margin="normal"
                        variant="outlined"
                        error={
                          !!errors.department
                            ?.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="seller"
                defaultValue={null as any}
                render={({ field }) => (
                  <Autocomplete
                    {...sellersProps}
                    {...field}
                    onChange={(_, value) => {
                      field.onChange(value)
                    }}
                    getOptionLabel={(item) => {
                      return (
                        sellersProps?.options?.find(
                          (p) =>
                            p?.id?.toString() === item?.id?.toString()
                        )?.name ?? ''
                      )
                    }}
                    isOptionEqualToValue={(
                      option,
                      value
                    ) =>
                      value === undefined || option.id.toString() === value.toString()
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Vendedor"
                        margin="normal"
                        variant="outlined"
                        error={
                          !!errors.seller
                            ?.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...register('city', { required: 'Ciudad es requerido' })}
                defaultValue={' '}
                error={!!errors?.city}
                label="Ciudad"
                name="city"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...register('route')}
                error={!!errors?.route}
                helperText={errors.route?.message as React.ReactNode}
                label="Ruta"
                name="route"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                {...register('latlog', { required: 'Latitud y Longitud es requerido' })}
                defaultValue={' '}
                error={!!errors?.latlog}
                helperText={errors.latlog?.message as ReactNode}
                label="Latitud y Longitud"
                name="latlog"
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
          <Button type="submit" variant="contained"><Save/>  Agregar</Button>
        </Stack>
      </form>
    </Edit>
  )
}
