import { GraphQLClient } from 'graphql-request'
import * as gql from 'gql-query-builder'
import pluralize from 'pluralize'
import camelCase from 'camelcase'
import axios from 'axios'
import { CrudFilters, DataProvider, HttpError } from '@refinedev/core'

const TOKEN_KEY: string = process.env.REACT_APP_TOKEN_KEY as string
export const client = new GraphQLClient(process.env.REACT_APP_API_URL as string)

function capitalizeFirstLetter (string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: '',
      statusCode: error.response?.status
    }

    return Promise.reject(customError)
  }
)

const operators = [['ne', 'neq'], ['nin', 'notIn'], ['contains', 'like'], ['nnull', 'isNot'], ['isAnyOf', 'in']]

const generateFilter = (filters?: CrudFilters) => {
  let queryFilter = ''
  if (filters) {
    filters.forEach((filter: any) => {
      if (filter.operator !== 'or' && filter.value !== undefined) {
        const { field, operator, value } = filter
        if (value !== null && value.length === 0) return false
        const operatorNest = operators.find(op => op[0] === operator)
        queryFilter += operatorNest?.toString() !== 'contains'
          ? `${queryFilter !== '' ? ',' : '{'}${field}: {${operatorNest ? operatorNest[1] : operator}: ${Array.isArray(value) ? `[${value.map(v => `${v}`)}]` : !operator.includes('id') ? `${value}` : `${value}`} }`
          : `${queryFilter !== '' ? ',' : '{'}${field}: {${operatorNest ? operatorNest[1] : operator}: "${value}" }}`
        return queryFilter
      }
    })
  }
  queryFilter += queryFilter !== '' ? '}' : ''
  return queryFilter
}

export const dataProvider = (client: GraphQLClient): DataProvider => {
  return {
    getList: async ({
      filters,
      sorters,
      resource,
      meta,
      pagination
    }) => {
      const camelResource = camelCase(resource)
      const operation = meta?.operation ?? camelResource
      const queryFilter = generateFilter(filters)
      const paging = pagination?.mode !== 'off' && pagination ? `paging: {${pagination?.pageSize ? `limit: ${pagination?.pageSize}` : ''} ${pagination?.current && pagination?.current > 1 ? `offset: ${pagination?.current === 2 ? pagination?.pageSize : (pagination?.current - 1) * 20}` : ''}}` : ''
      const filter = queryFilter !== '' ? `filter: ${queryFilter}` : ''
      const makeSort = sorters && sorters?.length > 0 ? `direction: ${(sorters[0]?.order || 'ASC').toUpperCase()} field: ${sorters[0].field}` : ''
      const sortGpl = makeSort !== '' ? `sorting: {${makeSort}}` : ''

      const options = paging || filter ? `(${paging} ${filter} ${sortGpl})` : ''
      const { query, variables } = gql.query({
        operation: `${operation}${options}`,
        variables: {
          ...meta?.variables
        },
        fields: meta?.fields
      })

      const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
      client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
      const response: any = await client.request(query, variables)
      return {
        data: response[operation].nodes ?? response[operation],
        total: response[operation].totalCount
      }
    },

    getMany: async ({ resource, ids, meta }) => {
      const camelResource = camelCase(resource)
      const operation = meta?.operation ?? camelResource
      let { query } = gql.query({
        operation,
        fields: meta?.fields
      })

      query = query.replace(operation, `${operation} (filter: { id: { in: [${ids}] } })`)
      const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
      client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
      const response: any = await client.request(query)

      return {
        data: response[operation].nodes ?? response[operation],
        total: response[operation].totalCount
      }
    },

    create: async ({ resource, variables, meta }) => {
      const singularResource = resource === 'people' || meta?.operation === 'people' ? 'people' : pluralize.singular(meta?.operation ? meta?.operation : resource)
      const camelCreateName = camelCase(`create - One${capitalizeFirstLetter(singularResource)} `)
      const stringify = JSON.stringify(variables)
      const graphQLvalues = stringify.replace(/"([^(")"]+)":/g, '$1:')
      const query = `mutation {
       ${camelCreateName} (input: {${singularResource}: ${graphQLvalues}}) {
      id}}`

      const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
      client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
      const response: any = await client.request(query)
      return {
        data: response[camelCreateName]
      }
    },

    createMany: async ({ resource, variables, meta }) => {
      const singularResource = pluralize.singular(meta?.operation ? meta?.operation : resource)
      const camelCreateName = camelCase(`create - One${capitalizeFirstLetter(singularResource)} `)

      const response: any = await Promise.all(
        variables.map(async (obj) => {
          const stringify = JSON.stringify(obj)
          const graphQLvalues = stringify.replace(/"([^(")"]+)":/g, '$1:')
          const query = `mutation {
           ${camelCreateName} (input: {${singularResource}: ${graphQLvalues}}) {
          id}}`

          const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
          client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
          const response: any = await client.request(query)
          return response[camelCreateName]
        })
      )
      return {
        data: response
      }
    },

    update: async ({ resource, id, variables, meta }) => {
      const singularResource = resource === 'people' || meta?.operation === 'People' ? 'People' : pluralize.singular(meta?.operation ?? resource)
      const camelUpdateName = camelCase(`update - One${singularResource} `)
      const stringify = JSON.stringify(variables)
      const graphQLvalues = stringify.replace(/"([^(")"]+)":/g, '$1:')
      const query = `mutation {
       ${camelUpdateName} (input: {id: ${`${id}`.length < 10 ? id : `"${id}"`}, update: ${graphQLvalues}}) {
      id}}`

      const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
      client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
      const response: any = await client.request(query)
      return {
        data: response[camelUpdateName]
      }
    },

    updateMany: async ({ resource, ids, variables, meta }) => {
      const camelUpdateName = camelCase(`update - Many${resource} `)
      const operation = meta?.operation ?? camelUpdateName
      const stringify = JSON.stringify(variables)
      const graphQLvalues = stringify.replace(/"([^(")"]+)":/g, '$1:')
      const query = `mutation {
       ${camelUpdateName} (input: {filter: {id: {in: [${ids}]}}, update: ${graphQLvalues}}) {updatedCount}}`

      const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
      client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
      const response: any = await client.request(query)
      return {
        data: response[operation][resource]
      }
    },

    getOne: async ({ resource, id, meta }) => {
      let { query, variables } = gql.query({
        operation: resource,
        fields: meta?.fields
      })
      const request = id.toString().length < 10 ? `${resource} (filter: { id: { eq: ${Number(id)}}})` : `${resource} (filter: { id: { eq: "${id}"}})`

      query = query.replace(resource, request)
      const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
      client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
      const response: any = await client.request(query, variables)

      const result = response[resource].nodes ?? response[resource]
      return {
        data: result[0]
      }
    },

    deleteOne: async ({ resource, id, meta }) => {
      const singularResource = resource === 'people' ? 'people' : pluralize.singular(resource)
      const camelDeleteName = camelCase(`delete -One${capitalizeFirstLetter(singularResource)} `)
      const operation = meta?.operation ?? camelDeleteName
      const query = `mutation {
        ${operation} (input: { id: ${/^-?[\d.]+(?:e-?\d+)?$/.test(id as any) ? id : `"${id}"`} }) {id}
      }`

      const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
      client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
      const response: any = await client.request(query)
      return {
        data: response[operation][singularResource]
      }
    },

    deleteMany: async ({ resource, ids, meta }) => {
      const camelDeleteName = camelCase(`delete -Many${resource} `)
      const operation = meta?.operation ?? camelDeleteName
      const query = `mutation {
        ${operation} (
          input: { filter: { id: { in: [${ids}]} } }
        ) {
          deletedCount}
      }`

      const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
      client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
      const response: any = await client.request(query)
      return {
        data: response
      }
    },

    getApiUrl: () => {
      throw Error('Not implemented on refine-graphql data provider.')
    },

    custom: async ({ url, method, meta }) => {
      const variables: any = meta?.variables
      if (method === 'patch') {
        const query = `mutation {${url} (input: ${variables.id}){id}}`
        const TOKEN_JWT = localStorage.getItem(TOKEN_KEY)
        client.setHeader('authorization', `Bearer ${TOKEN_JWT} `)
        const response: any = await client.request(query)
        return {
          data: response
        }
      } else {
        throw Error(
          'GraphQL need to operation, fields and variables values in meta object.'
        )
      }
    }
  }
}
