import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, ButtonGroup, Grid, Input, Paper, Skeleton, Stack, Typography } from '@mui/material'
import { ICustomer, IMapDefault } from 'interfaces'
import { useJsApiLoader, GoogleMap, Autocomplete, DirectionsRenderer } from '@react-google-maps/api'
import { Adjust, Clear } from '@mui/icons-material'
import { intervalToDuration, formatDuration } from 'date-fns'

export function RouteMap ({ defaultProps, customers }: {defaultProps: IMapDefault, customers: ICustomer[]}) {
  useEffect(() => {
    calculateRoute()
  }, [customers])

  const google = window.google
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_ID as string,
    libraries: ['places']
  })
  // const [desiredZoomLevel, setDesiredZoomLevel] = useState(12)
  // const [zoomLevel, setZoomLevel] = useState(defaultProps.zoom)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [directionsResponse, setDirectionsResponse] = useState<google.maps.Map | null>(null)
  const [distance, setDistance] = useState<string>('')
  const [duration, setDuration] = useState<string>('')
  const originRef = useRef<any>()
  const destiantionRef = useRef<any>()
  const [customMarkers, setCustomMarkers] = useState<any[]>([])

  async function calculateRoute () {
    if (originRef?.current?.children[0].value === '' &&
    (destiantionRef?.current?.children[0].value === '' || customers.length > 0)) {
      return null
    }
    if (!google) return null
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: originRef?.current?.children[0].value || '',
      waypoints: customers.map((c) => {
        return { location: c.latlog, stopover: true }
      }),
      optimizeWaypoints: true,
      destination: destiantionRef?.current?.children[0].value || '',
      travelMode: google.maps.TravelMode.DRIVING
    })
    setDirectionsResponse(results as any)

    const dist = results.routes[0].legs.reduce((a, b) => a + (b.distance?.value || 0), 0)
    const dur = results.routes[0].legs.reduce((a, b) => a + (b.duration?.value || 0), 0)
    setDistance(`${(dist / 1000).toFixed(1)} KM`)
    setDuration(formatDuration(intervalToDuration({
      start: 0,
      end: dur * 1000
    }), {
      format: ['days', 'hours', 'minutes'],
      delimiter: ', '
    }))

    createCustomMarkers(results, customers)
  }

  const createCustomMarkers = (results: any, customers: any) => {
    // Remover marcadores anteriores
    customMarkers.forEach((marker: any) => marker.setMap(null))

    const legs = results.routes[0].legs
    const newMarkers: any[] = []

    // Adiciona o marcador de origem
    newMarkers.push(createMarker(legs[0].start_location, 'Origen'))

    // Adiciona marcadores para cada cliente, na ordem otimizada
    legs.forEach((leg: any, index: number) => {
      if (index < customers.length) {
        newMarkers.push(createMarker(leg.end_location, customers[index].name))
      }
    })

    // Adiciona o marcador de destino
    newMarkers.push(createMarker(legs[legs.length - 1].end_location, 'Destino'))

    setCustomMarkers(newMarkers)
  }

  const createMarker = (location: any, label: string) => {
    // const shouldShowName = zoomLevel >= desiredZoomLevel

    return new google.maps.Marker({
      position: { lat: location.lat(), lng: location.lng() },
      map,
      label: {
        text: label,
        fontWeight: 'bold',
        fontSize: '14px',
        color: 'black'
      }
    })
  }

  function clearRoute () {
    setDirectionsResponse(null)
    setDistance('')
    setDuration('')
  }

  if (!isLoaded || loadError) {
    return (
      <Skeleton width="100%">
        <Typography>.</Typography>
      </Skeleton>
    )
  }

  // const handleZoomChange = () => {
  //   if (map && map.getZoom()) {
  //     const newZoom = map.getZoom()
  //     if (newZoom) setZoomLevel(newZoom)
  //   }
  // }

  return (
    <Paper sx={{ flex: 1, mx: 'auto', width: '100%', p: 1 }}>
      <Stack direction="column" spacing={1} sx={{ height: 1 }}>
        <Box
          style={{ zIndex: 1 }}
          color='white'
          boxShadow='initial'
        >
          <Stack spacing={2} justifyContent='space-between'>
            <Grid container>
              <Grid item xs={2} lg={2}>
                <Autocomplete>
                  <Input type='text' placeholder='Origem' ref={originRef} />
                </Autocomplete>
              </Grid>
              <Grid item xs={2} lg={2}>
                <Autocomplete>
                  <Input type='text'placeholder='Destino' ref={destiantionRef}/>
                </Autocomplete>
              </Grid>
              <Grid item xs={2} lg={2}>
                <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                >
                  <Typography variant='h6' color='gray'>
                    Distância: {distance}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={4} lg={4}>
                <Grid
                  container
                  alignItems='left'
                  justifyContent='start'
                >
                  <Typography variant='h6' color='gray'>
                    Duração: {duration}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={2} lg={2}>
                <Grid
                  container
                  alignItems='center'
                  justifyContent='flex-end'
                >
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    color='info'
                  >
                    <Button color='primary' onClick={calculateRoute}>Calcular Rota</Button>
                    <Button onClick={() => map?.panTo(defaultProps.center)}><Adjust/></Button>
                    <Button color='error' onClick={clearRoute}><Clear/></Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Box>
        <Box
          boxShadow='initial'
          color='HighlightText'
          sx={{ height: '576px', width: '100%', position: 'relative' }}
        >
          <GoogleMap
            mapContainerStyle={{ overflow: 'visible', width: '100%', height: '576px' }}
            center={defaultProps.center}
            zoom={defaultProps.zoom}
            options={{
              zoomControl: true,
              streetViewControl: true,
              mapTypeControl: true,
              fullscreenControl: false
            }}
            onLoad={(map) => setMap(map)}
            // onZoomChanged={handleZoomChange}
          >
            {
              directionsResponse && <DirectionsRenderer options={{
                markerOptions: {
                  anchorPoint: new google.maps.Point(0, -100),
                  label: {
                    text: 'teste',
                    fontWeight: 'bold',
                    fontSize: '14px'
                  }
                },
                suppressMarkers: true
              }} directions={directionsResponse as any}
              />
            }
          </GoogleMap>
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2
          }}
        >
          <Typography variant="body2" gutterBottom>
            Zoom para visualização de nomes
          </Typography>
          <Slider
            aria-label="Nível de Zoom Desejado"
            value={desiredZoomLevel}
            onChange={(event, newValue: any) => setDesiredZoomLevel(newValue)}
            step={1}
            marks
            min={1}
            max={20}
            valueLabelDisplay="auto"
          />
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Zoom Atual: {desiredZoomLevel}
          </Typography>
        </Box> */}
      </Stack>
    </Paper>
  )
}
