import React, { useState } from 'react'
import { HttpError, useTranslate, useList } from '@refinedev/core'
import { List, useDataGrid } from '@refinedev/mui'
import { Theme } from '@mui/material/styles'
import { DataGrid, GridColDef, GridRowId, GridToolbar } from '@mui/x-data-grid'
import { Button, Grid, Typography } from '@mui/material'
import { RouteMap } from 'components/dashboard/route-map'
import { ICustomer, IDefaultFilters, IDepartment, IMapDefault, ISeller } from 'interfaces'

export function RouteList () {
  const t = useTranslate()
  const [selectionAddModel, setSelectionAddModel] = useState<GridRowId[]>([])
  const [selectionRemoveModel, setSelectionRemoveModel] = useState<GridRowId[]>([])
  const [customersAddSelected, setCustomersAddSelected] = useState<ICustomer[]>([])
  const [customersRemoveSelected, setCustomersRemoveSelected] = useState<ICustomer[]>([])
  const [customers, setCustomers] = useState<ICustomer[]>([])

  const defaultProps: IMapDefault = {
    center: {
      lat: -23.5571815,
      lng: -46.3403261
    },
    zoom: 8
  }

  const { data: dataListSellers } = useList<ISeller>({
    resource: 'sellers',
    meta: {
      operation: 'sellers',
      fields: [
        'id',
        'name'
      ]
    },
    pagination: {
      mode: 'off'
    }
  })

  const { data: dataListDepartments } = useList<IDepartment>({
    resource: 'departments',
    meta: {
      operation: 'departments',
      fields: [
        'id',
        'name'
      ]
    },
    pagination: {
      mode: 'off'
    }
  })
  const columns: GridColDef<any>[] = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 200,
      type: 'string'
    },
    {
      field: 'city',
      headerName: 'Ciudad',
      flex: 1,
      minWidth: 200,
      type: 'string'
    },
    {
      field: 'sellerId',
      headerName: 'Vendedor',
      flex: 1,
      minWidth: 150,
      type: 'singleSelect',
      valueOptions: dataListSellers?.data.map(obj => {
        return {
          value: `"${obj.id}"`,
          label: obj.name
        }
      }),
      renderCell: function render ({ row }) {
        return row.seller?.name
      }
    },
    {
      field: 'departmentId',
      headerName: 'Departamento',
      flex: 1,
      minWidth: 150,
      type: 'singleSelect',
      valueOptions: dataListDepartments?.data.map(obj => {
        return {
          value: `"${obj.id}"`,
          label: obj.name
        }
      }),
      renderCell: function render ({ row }) {
        return row.department?.name
      }
    },
    {
      field: 'route',
      headerName: 'Ruta',
      flex: 1,
      minWidth: 200,
      type: 'string'
    }
  ]

  const columnsRoute: GridColDef<ICustomer>[] = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 200,
      filterable: false
    }
  ]
  const { dataGridProps } = useDataGrid<ICustomer,
  HttpError,
  IDefaultFilters
  >({
    resource: 'customers',

    meta: {
      operation: 'customers',
      fields: [
        {
          nodes: [
            'id',
            'name',
            'city',
            'route',
            'latlog',
            'createdAt',
            'updatedAt',
            'deletedAt',
            {
              seller: ['id', 'name']
            },
            {
              department: ['id', 'name']
            },
            {
              createdBy: ['id', 'name']
            },
            {
              updatedBy: ['id', 'name']
            }
          ]
        },
        'totalCount'
      ]
    },

    pagination: {
      current: 1,
      pageSize: 20
    },

    filters: {
      permanent: [
        {
          field: 'id',
          operator: 'nin',
          value: customers.map(c => `"${c.id}"`)
        }
      ]
    },

    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    }
  })

  function addCustomer (customersAddSelected:ICustomer[]) {
    customersAddSelected.forEach(c => setCustomers(oldArray => [...oldArray, c]))
    setSelectionAddModel([])
  }
  function removeCustomer (customersRemoveSelected:ICustomer[]) {
    customersRemoveSelected.forEach(c => setCustomers(oldArray => oldArray.filter(old => old.id !== c.id)))
    setSelectionRemoveModel([])
  }
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant="h5">{t('routes.titles.list')}</Typography>
      </Grid>
      <Grid item xs={12} lg={8}>
        <List
          headerButtons={() => (
            <Button variant="outlined" color="primary" onClick={() => addCustomer(customersAddSelected)}>
              Agregar
            </Button>
          )}
          headerProps={{
            title: <Typography variant="h5">Agregar Clientes</Typography>
          }}>
          <DataGrid
            {...dataGridProps}
            autoHeight
            pagination
            filterMode='server'
            paginationMode='server'
            density='compact'
            columns={columns}
            keepNonExistentRowsSelected
            checkboxSelection={true}
            rowSelectionModel={selectionAddModel}
            onRowSelectionModelChange={(ids: any) => {
              const selectedIDs = new Set(ids)
              const rows = dataGridProps?.rows.filter((row) =>
                selectedIDs.has(row.id)
              )
              setCustomersAddSelected(rows || [])
              setSelectionAddModel(ids)
            }}
            disableRowSelectionOnClick
            slots={{
              toolbar: GridToolbar
            }}
            slotProps={{
              filterPanel: {
                columnsSort: 'asc',
                filterFormProps: {
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small'
                    }
                  },
                  deleteIconProps: {
                    sx: {
                      '& .MuiSvgIcon-root': { color: '#d32f2f' }
                    }
                  }
                },
                sx: {
                  '& .MuiDataGrid-filterForm': { p: 2 },
                  '& .MuiDataGrid-filterForm:nth-child(even)': {
                    backgroundColor: (theme: Theme) =>
                      theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
                  },
                  '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                  '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormValueInput': { width: 200 }
                }
              }
            }}
          />
        </List>
      </Grid>
      <Grid item xs={12} lg={4}>
        <List
          headerButtons={() => (
            <Button variant="outlined" color="error" onClick={() => removeCustomer(customersRemoveSelected)}>
              Remover
            </Button>
          )}
          headerProps={{
            title: <Typography variant="h5">Clientes en Ruta</Typography>
          }}>
          <DataGrid
            checkboxSelection={true}
            disableRowSelectionOnClick
            rows={customers}
            autoHeight
            density='compact'
            columns={columnsRoute}
            rowSelectionModel={selectionRemoveModel}
            onRowSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids)
              const rows = customers.filter((row) =>
                selectedIDs.has(row.id)
              )
              setCustomersRemoveSelected(rows || [])
              setSelectionRemoveModel(ids)
            }}
          />
        </List>
      </Grid>
      <Grid item xs={12}>
        <RouteMap
          defaultProps={defaultProps}
          customers={customers}
        />
      </Grid>
    </Grid>
  )
}
