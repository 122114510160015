import React from 'react'
import { Create } from '@refinedev/mui'
import { Box, TextField, Grid, Typography } from '@mui/material'
import { useForm } from '@refinedev/react-hook-form'

export function SellerCreate () {
  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors }
  } = useForm()

  return (
    <Create
      headerProps={{
        title: <Typography variant="h5">Criar Vendedor</Typography>
      }}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        autoComplete="off"
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              {...register('name', { required: true })}
              error={!!errors?.name}
              helperText={errors.name?.message as React.ReactNode}
              label="Nombre"
              name="name"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...register('email')}
              error={!!errors?.email}
              helperText={errors.email?.message as React.ReactNode}
              label="E-mail"
              name="email"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...register('phone')}
              error={!!errors?.phone}
              helperText={errors.phone?.message as React.ReactNode}
              label="Telefono"
              name="phone"
              margin="normal"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Create>
  )
}
