import React, { useEffect, useState } from 'react'
import { DateField, List, DeleteButton, EditButton, useDataGrid } from '@refinedev/mui'
import { Theme } from '@mui/material/styles'
import { Stack, Typography, Card, Grid, Slider } from '@mui/material'
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid'
import {
  IDefaultFilters,
  ICustomer,
  IDepartment,
  IMapDefault,
  ISeller
} from 'interfaces'
import { HttpError, useList, useTranslate } from '@refinedev/core'
import { CustomerMap } from 'components/dashboard/map'

export function CustomerList () {
  const t = useTranslate()
  const data = [
    {
      State: 'Alto Paraguay',
      Country: 'Paraguay',
      strokeColor: 'rgb(164, 210, 92)',
      fillColor: 'rgb(164, 210, 92)'
    },
    {
      State: 'Alto Paraná',
      Country: 'Paraguay',
      strokeColor: 'rgb(120, 222, 87)',
      fillColor: 'rgb(120, 222, 87)'
    },
    {
      State: 'Amambay',
      Country: 'Paraguay',
      strokeColor: 'rgb(236, 182, 81)',
      fillColor: 'rgb(236, 182, 81)'
    },
    {
      State: 'Asunción',
      Country: 'Paraguay',
      strokeColor: 'rgb(151, 212, 67)',
      fillColor: 'rgb(151, 212, 67)'
    },
    {
      State: 'Boquerón',
      Country: 'Paraguay',
      strokeColor: 'rgb(211, 190, 183)',
      fillColor: 'rgb(211, 190, 183)'
    },
    {
      State: 'Caaguazú',
      Country: 'Paraguay',
      strokeColor: 'rgb(216, 187, 184)',
      fillColor: 'rgb(216, 187, 184)'
    },
    {
      State: 'Caazapá',
      Country: 'Paraguay',
      strokeColor: 'rgb(131, 217, 215)',
      fillColor: 'rgb(131, 217, 215)'
    },
    {
      State: 'Canindeyú',
      Country: 'Paraguay',
      strokeColor: 'rgb(236, 177, 234)',
      fillColor: 'rgb(236, 177, 234)'
    },
    {
      State: 'Central',
      Country: 'Paraguay',
      strokeColor: 'rgb(144, 208, 62)',
      fillColor: 'rgb(144, 208, 62)'
    },
    {
      State: 'Concepción',
      Country: 'Paraguay',
      strokeColor: 'rgb(230, 225, 38)',
      fillColor: 'rgb(230, 225, 38)'
    },
    {
      State: 'Cordillera',
      Country: 'Paraguay',
      strokeColor: 'rgb(201, 200, 113)',
      fillColor: 'rgb(201, 200, 113)'
    },
    {
      State: 'Guairá',
      Country: 'Paraguay',
      strokeColor: 'rgb(229, 55, 152)',
      fillColor: 'rgb(229, 55, 152)'
    },
    {
      State: 'Itapúa',
      Country: 'Paraguay',
      strokeColor: 'rgb(242, 225, 113)',
      fillColor: 'rgb(242, 225, 113)'
    },
    {
      State: 'Misiones',
      Country: 'Paraguay',
      strokeColor: 'rgb(244, 63, 22)',
      fillColor: 'rgb(244, 63, 22)'
    },
    {
      State: 'Ñeembucú',
      Country: 'Paraguay',
      strokeColor: 'rgb(182, 200, 136)',
      fillColor: 'rgb(182, 200, 136)'
    },
    {
      State: 'Paraguarí',
      Country: 'Paraguay',
      strokeColor: 'rgb(242, 225, 113)',
      fillColor: 'rgb(242, 225, 113)'
    },
    {
      State: 'Presidente Hayes',
      Country: 'Paraguay',
      strokeColor: 'rgb(230, 184, 62)',
      fillColor: 'rgb(230, 184, 62)'
    },
    {
      State: 'San Pedro',
      Country: 'Paraguay',
      strokeColor: 'rgb(231, 115, 51)',
      fillColor: 'rgb(231, 115, 51)'
    }
  ]
  const [departments, setDepartments] = useState<any[]>([])
  const [desiredZoomLevel, setDesiredZoomLevel] = useState(12)

  const defaultProps: IMapDefault = {
    center: {
      lat: -23.4227878,
      lng: -57.469136
    },
    zoom: 6
  }
  const { data: dataListSellers } = useList<ISeller>({
    resource: 'sellers',
    meta: {
      operation: 'sellers',
      fields: ['id', 'name']
    },
    pagination: {
      mode: 'off'
    }
  })

  const { data: dataListDepartments } = useList<IDepartment>({
    resource: 'departments',
    meta: {
      operation: 'departments',
      fields: ['id', 'name']
    },
    pagination: {
      mode: 'off'
    }
  })
  const columns: GridColDef<ICustomer>[] = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      minWidth: 200,
      type: 'string'
    },
    {
      field: 'city',
      headerName: 'Ciudad',
      flex: 1,
      minWidth: 200,
      type: 'string'
    },
    {
      field: 'sellerId',
      headerName: 'Vendedor',
      flex: 1,
      minWidth: 150,
      type: 'singleSelect',
      valueOptions: dataListSellers?.data.map((obj) => {
        return {
          value: `"${obj.id}"`,
          label: obj.name
        }
      }),
      renderCell: function render ({ row }) {
        return row.seller?.name
      }
    },
    {
      field: 'departmentId',
      headerName: 'Departamento',
      flex: 1,
      minWidth: 150,
      type: 'singleSelect',
      valueOptions: dataListDepartments?.data.map((obj) => {
        return {
          value: `"${obj.id}"`,
          label: obj.name
        }
      }),
      renderCell: function render ({ row }) {
        return row.department?.name
      }
    },
    {
      field: 'route',
      headerName: 'Ruta',
      flex: 1,
      minWidth: 200,
      type: 'string'
    },
    {
      field: 'latlog',
      headerName: 'Latitud y Longitud',
      flex: 1,
      minWidth: 250,
      type: 'string'
    },
    {
      field: 'createdAt',
      headerName: 'Criado em',
      flex: 1,
      minWidth: 150,
      type: 'dateTime',
      valueGetter: function params ({ row }) {
        return new Date(row.createdAt)
      },
      filterable: false,
      renderCell: function render ({ row }) {
        return <DateField format="HH:mm D/M/YY" value={row.createdAt} />
      }
    },
    {
      headerName: 'Ações',
      field: 'actions',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      type: 'action',
      renderCell: function render ({ row }) {
        return (
          <Stack direction="row" spacing={1}>
            <EditButton
              hideText
              recordItemId={row.id}
              resource="customers"
            />
            <DeleteButton hideText recordItemId={row.id} />
          </Stack>
        )
      }
    }
  ]

  const { dataGridProps } = useDataGrid<ICustomer, HttpError, IDefaultFilters>({
    resource: 'customers',

    meta: {
      operation: 'customers',
      fields: [
        {
          nodes: [
            'id',
            'name',
            'city',
            'route',
            'latlog',
            'createdAt',
            'updatedAt',
            'deletedAt',
            {
              seller: ['id', 'name']
            },
            {
              department: ['id', 'name']
            },
            {
              createdBy: ['id', 'name']
            },
            {
              updatedBy: ['id', 'name']
            }
          ]
        },
        'totalCount'
      ]
    },

    pagination: {
      current: 1,
      pageSize: 20
    },

    sorters: {
      initial: [
        {
          field: 'name',
          order: 'asc'
        }
      ]
    }
  })

  const { data: dataMap } = useList<ICustomer>({
    resource: 'allCustomers',
    meta: {
      operation: 'allCustomers',
      fields: [
        'id',
        'name',
        'city',
        'route',
        'latlog',
        'createdAt',
        'updatedAt',
        'deletedAt',
        {
          seller: ['id', 'name']
        },
        {
          department: ['id', 'name']
        },
        {
          createdBy: ['id', 'name']
        },
        {
          updatedBy: ['id', 'name']
        }
      ]
    },
    pagination: {
      mode: 'off'
    }
  })

  useEffect(() => {
    // const fetchAPICoordinates = async () => {
    //   for (const item of data) {
    //     const stateName = encodeURIComponent(item.State)
    //     const countryCode = encodeURIComponent(item.Country)
    //     const url = `https://nominatim.openstreetmap.org/search.php?country=${countryCode}&state=${stateName}&polygon_geojson=1&format=jsonv2`
    //     const allCoordinates: any[] = []
    //     const saveData = (data: any, fileName: string) => {
    //       const json = JSON.stringify(data)
    //       console.log('json', json)
    //       const blob = new Blob([json], { type: 'application/json' })
    //       const href = URL.createObjectURL(blob)
    //       const link = document.createElement('a')
    //       link.href = href
    //       link.download = fileName
    //       document.body.appendChild(link)
    //       link.click()
    //       document.body.removeChild(link)
    //     }
    //     // const processCoordinates = (coordinates: any) => {
    //     //   if (coordinates.length > 2) {
    //     //     const googleMapsCoordinates = coordinates.map((coord: any) => ({
    //     //       lat: coord[1], // Latitude
    //     //       lng: coord[0] // Longitude
    //     //     }))
    //     //     console.log(googleMapsCoordinates)
    //     //     allCordinates.push(googleMapsCoordinates)
    //     //   }
    //     // }
    //     try {
    //       const response = await fetch(url)
    //       const json = await response.json()
    //       console.log(json)
    //       json.forEach((item: any) => {
    //         if (item.geojson && (item.geojson.type === 'Polygon' || item.geojson.type === 'MultiPolygon')) {
    //           const coordinatesArray = item.geojson.type === 'Polygon' ? [item.geojson.coordinates] : item.geojson.coordinates
    //           coordinatesArray.forEach((polygon: any) => {
    //             const exteriorRing = polygon[0] // Pegamos apenas o contorno exterior
    //             if (exteriorRing.length > 2) {
    //               const googleMapsCoordinates = exteriorRing.map((coord: any) => ({
    //                 lat: coord[1], // Latitude
    //                 lng: coord[0] // Longitude
    //               }))
    //               allCoordinates.push(...googleMapsCoordinates)
    //             }
    //           })
    //         }
    //       })

    //       if (allCoordinates.length > 0) {
    //         saveData(allCoordinates, `${countryCode}-${stateName}.json`)
    //       }
    //     } catch (error) {
    //       console.error('Error fetching data:', error)
    //     }
    //   }
    // }

    const fetchCoordinates = async () => {
      for (const item of data) {
        const fileName = `${item.Country}-${item.State}.json`
        const filePath = `/data/polygons/${fileName}`
        try {
          const response = await fetch(filePath)
          const json = await response.json()
          setDepartments((prevdDpartments) => [
            ...prevdDpartments,
            {
              coordinates: json,
              ...item
            }
          ])
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
    }

    fetchCoordinates()
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <List
          headerProps={{
            title: <Typography variant="h5">Lista de Clientes</Typography>
          }}
        >
          <DataGrid
            {...dataGridProps}
            autoHeight
            pagination
            filterMode="server"
            paginationMode="server"
            density="compact"
            columns={columns}
            slots={{
              toolbar: GridToolbar
            }}
            slotProps={{
              filterPanel: {
                columnsSort: 'asc',
                filterFormProps: {
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small'
                    }
                  },
                  deleteIconProps: {
                    sx: {
                      '& .MuiSvgIcon-root': { color: '#d32f2f' }
                    }
                  }
                },
                sx: {
                  '& .MuiDataGrid-filterForm': { p: 2 },
                  '& .MuiDataGrid-filterForm:nth-child(even)': {
                    backgroundColor: (theme: Theme) =>
                      theme.palette.mode === 'dark' ? '#444' : '#f5f5f5'
                  },
                  '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
                  '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormValueInput': { width: 200 }
                }
              }
            }}
          />
        </List>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ height: '100%', minHeight: '600px' }}>
          <Grid container alignItems="center" justifyContent="space-between" sx={{ paddingX: { xs: 4 } }}>
            <Grid item>
              <Typography variant="h5">{t('dashboard.map.title')}</Typography>
            </Grid>
            <Grid item margin={1}>
              <Typography variant="body2" gutterBottom>
                Zoom para visualização de nomes
              </Typography>
              <Slider
                aria-label="Nível de Zoom Desejado"
                value={desiredZoomLevel}
                onChange={(event, newValue: any) => setDesiredZoomLevel(newValue)}
                step={1}
                marks
                min={1}
                max={20}
                valueLabelDisplay="auto"
              />
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Zoom Atual: {desiredZoomLevel}
              </Typography>
            </Grid>
          </Grid>
          <CustomerMap
            defaultProps={defaultProps}
            desiredZoomLevel={desiredZoomLevel}
            customers={dataMap?.data || []}
            departments={departments}
          />
        </Card>
      </Grid>
    </Grid>
  )
}
