import React from 'react'
import { Create } from '@refinedev/mui'
import { Box, TextField, Grid, Typography, Button, Stack } from '@mui/material'
import { useForm } from '@refinedev/react-hook-form'
import { FieldValues } from 'react-hook-form'
import { HttpError } from '@refinedev/core'
import { Save } from '@mui/icons-material'
import { IDepartment } from 'interfaces'

export function DepartmentCreate () {
  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IDepartment, HttpError>()

  const handleSubmitCreate = (values: FieldValues) => {
    onFinish(values)
  }

  return (
    <Create
      resource="departments"
      headerProps={{
        title: <Typography variant="h5">Crear Departamento</Typography>
      }}
      footerButtons={<></>}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}
    >
      <form onSubmit={handleSubmit(handleSubmitCreate)}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', paddingBottom: 2 }}
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                {...register('name', {
                  required: 'Nombre es requerido'
                })}
                error={!!errors?.name}
                helperText={errors.name?.message as React.ReactNode}
                margin="normal"
                fullWidth
                id="name"
                label="Nombre"
                name="name"
                required
                defaultValue={''}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack
          justifyContent="end"
          alignContent="end"
          alignItems="end"
          direction="row"
          spacing={4}
        >
          <Button type="submit" variant="contained">
            <Save /> Agregar
          </Button>
        </Stack>
      </form>
    </Create>
  )
}
