import React from 'react'
import { Authenticated, Refine } from '@refinedev/core'
import {
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
  ThemedTitleV2
} from '@refinedev/mui'
import { CssBaseline, GlobalStyles } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ColorModeContextProvider } from 'contexts'
import { authProvider } from './services/auth-provider'
import {
  CarRental,
  ContactPhone,
  Dashboard,
  Group,
  People,
  StoreOutlined
} from '@mui/icons-material'
import { client, dataProvider } from 'services/data-provider'
import { LoginPage } from 'components/login'
import { UserCreate, UserEdit, UserList, UserShow } from 'pages/users'
import { DashboardPage } from 'pages/dashboard'
import {
  DepartmentList,
  DepartmentCreate,
  DepartmentEdit
} from 'pages/departments'
import { SellerList, SellerCreate, SellerEdit } from 'pages/sellers'
import { CustomerList, CustomerCreate, CustomerEdit } from 'pages/customers'
import { RouteList } from 'pages/routes'
import { BrowserRouter, Link, Outlet, Route, Routes } from 'react-router-dom'
import { Header } from 'components/layout'
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource
} from '@refinedev/react-router-v6'

function App () {
  const { t, i18n } = useTranslation()

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language
  }

  return (
    <BrowserRouter>
      <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
        <RefineSnackbarProvider>
          <Refine
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            dataProvider={dataProvider(client)}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            options={{
              mutationMode: 'undoable',
              undoableTimeout: 3000,
              syncWithLocation: true
            }}
            resources={[
              {
                name: 'dashboards',
                list: '/',
                meta: {
                  icon: <Dashboard />
                }
              },
              {
                name: 'routes',
                meta: {
                  label: t('routes.routes')
                },
                icon: <CarRental />,
                list: '/rutas'
              },
              {
                name: 'users',
                meta: {
                  label: t('sider.user')
                },
                icon: <People />,
                list: '/usuarios',
                create: '/usuarios/crear',
                show: '/usuarios/mirar/:id',
                edit: '/usuarios/cambiar/:id'
              },
              {
                name: 'departments',
                meta: {
                  label: t('sider.department')
                },
                icon: <StoreOutlined />,
                list: '/departamentos',
                create: '/departamentos/crear',
                edit: '/departamentos/cambiar/:id'
              },
              {
                name: 'sellers',
                meta: {
                  label: t('sider.seller')
                },
                icon: <Group />,
                list: '/vendedores',
                create: '/vendedores/crear',
                edit: '/vendedores/cambiar/:id'
              },
              {
                name: 'customers',
                meta: {
                  label: t('sider.customer')
                },
                icon: <ContactPhone />,
                list: '/clientes',
                create: '/clientes/crear',
                edit: '/clientes/cambiar/:id'
              }
            ]}
          >
            <Routes>
              <Route
                element={
                  <Authenticated
                    key={'login'}
                    fallback={<CatchAllNavigate to="/login" />}
                  >
                    <ThemedLayoutV2
                      Header={() => <Header />}
                      Title={({ collapsed }: { collapsed: boolean }) => (
                        <ThemedTitleV2
                          collapsed={collapsed}
                          text={<Link to="/">
                            {collapsed
                              ? (
                                <img src="/ansalogowhite.min.png" alt="Logo Minimizada route" width="40px" />
                              )
                              : (
                                <img src="/ansalogowhite.png" alt="Logo route" width="80px" />
                              )}
                          </Link>}
                          icon={
                            <Link to="/">
                              {collapsed
                                ? (
                                  <img src="/ansalogowhite.min.png" alt="Logo Minimizada route" width="40px" />
                                )
                                : (
                                  <img src="/ansalogowhite.png" alt="Logo route" width="80px" />
                                )}
                            </Link>
                          }
                        />
                      )}
                      dashboard={true}
                      initialSiderCollapsed={false}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route index element={<DashboardPage />} />
                <Route path="/rutas">
                  <Route index element={<RouteList />} />
                </Route>
                <Route path="/usuarios">
                  <Route index element={<UserList />} />
                  <Route path="crear" element={<UserCreate />} />
                  <Route path="cambiar/:id" element={<UserEdit />} />
                  <Route path="mirar/:id" element={<UserShow />} />
                </Route>
                <Route path="/departamentos">
                  <Route index element={<DepartmentList />} />
                  <Route path="crear" element={<DepartmentCreate />} />
                  <Route path="cambiar/:id" element={<DepartmentEdit />} />
                </Route>
                <Route path="/vendedores">
                  <Route index element={<SellerList />} />
                  <Route path="crear" element={<SellerCreate />} />
                  <Route path="cambiar/:id" element={<SellerEdit />} />
                </Route>
                <Route path="/clientes">
                  <Route index element={<CustomerList />} />
                  <Route path="crear" element={<CustomerCreate />} />
                  <Route path="cambiar/:id" element={<CustomerEdit />} />
                </Route>
              </Route>
              <Route
                element={
                  <Authenticated
                    key={'NavigateToResource'}
                    fallback={<Outlet />}
                  >
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<LoginPage />} />
              </Route>
            </Routes>
          </Refine>
        </RefineSnackbarProvider>
      </ColorModeContextProvider>
    </BrowserRouter>
  )
}

export default App
