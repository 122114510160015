import React, { ReactNode } from 'react'
import { useForm } from '@refinedev/react-hook-form'
import { Edit, RefreshButton } from '@refinedev/mui'
import { Box, TextField, Grid, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

export function UserEdit () {
  const params: any = useParams()
  const showId = params.id
  const meta = {
    operation: 'Users',
    fields: [
      'id',
      'name',
      'access',
      'email'
    ]
  }

  const {
    refineCore: { formLoading },
    saveButtonProps,
    register,
    formState: { errors }
  } = useForm({
    refineCoreProps: {
      action: 'edit',
      id: showId,
      resource: 'users',
      invalidates: ['all'],
      meta
    }
  })

  return (
    <Edit
      headerProps={{
        title: <Typography variant="h5">Editar Usuario</Typography>,
        action: <RefreshButton resource='users' meta={meta} recordItemId={showId} />
      }}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column' }}
        autoComplete="off"
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <TextField
              {...register('name', { required: 'Lo nombre es obligatorio' })}
              defaultValue={' '}
              error={!!errors?.name}
              helperText={errors.name?.message as ReactNode}
              label="Nombre"
              name="name"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...register('email')}
              defaultValue={' '}
              error={!!errors?.email}
              label="E-mail"
              name="email"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              {...register('access', { required: 'Lo acceso es obligatorio' })}
              defaultValue={' '}
              error={!!errors?.access}
              helperText={errors.access?.message as ReactNode}
              label="Acesso ao sistema"
              name="access"
              margin="normal"
              required
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </Edit>
  )
}
