import React from 'react'
import { useForm } from '@refinedev/react-hook-form'
import { FieldValues } from 'react-hook-form'
import { Edit, RefreshButton } from '@refinedev/mui'
import { Box, TextField, Grid, Typography, Stack, Button } from '@mui/material'
import { IDepartment } from 'interfaces'
import { HttpError } from '@refinedev/core'
import { Save } from '@mui/icons-material'
import { useParams } from 'react-router-dom'

export function DepartmentEdit () {
  const params: any = useParams()
  const showId = params.id
  const meta = {
    operation: 'Departments',
    fields: [
      'id',
      'name',
      'createdAt'
    ]
  }

  const {
    refineCore: { formLoading, onFinish },
    saveButtonProps,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IDepartment, HttpError>({
    refineCoreProps: {
      action: 'edit',
      id: showId,
      resource: 'departments',
      invalidates: ['all'],
      meta
    }
  })

  const handleSubmitEdit = (values: FieldValues) => {
    onFinish(values)
  }

  return (
    <Edit
      headerProps={{
        title: <Typography variant="h5">Editar Departmento</Typography>,
        action: <RefreshButton resource='departments' meta={meta} recordItemId={showId} />
      }}
      footerButtons={<></>}
      isLoading={formLoading}
      saveButtonProps={saveButtonProps}>
      <form onSubmit={handleSubmit(handleSubmitEdit)}>
        <Box
          component="form"
          sx={{ display: 'flex', flexDirection: 'column', paddingBottom: 2 }}
          autoComplete="off"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                {...register('name', {
                  required: 'Nombre es requerido'
                })}
                error={!!errors?.name}
                helperText={errors.name?.message as React.ReactNode}
                margin="normal"
                fullWidth
                id="name"
                label="Nombre"
                name="name"
                required
                defaultValue={' '}
              />
            </Grid>
          </Grid>
        </Box>
        <Stack justifyContent='end' alignContent='end' alignItems='end' direction='row' spacing={4}>
          <Button type="submit" variant="contained"><Save/>  Agregar</Button>
        </Stack>
      </form>
    </Edit>
  )
}
